<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
        >
        <h3 class="text-decoration-underline">Transaction Configuration:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="9"
          md="4"
        >
          <v-checkbox
            v-model="editItem.usedLastBusinessDate"
            label="Enable Last Used Business Date"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="9"
          md="4"
        >
          <v-checkbox
            v-model="editItem.disableAutofillMortgagee"
            label="Disable Autofill Mortgagee from Received Of"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">Print Settings:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" md="4">
          <v-text-field
            v-model.number="editItem.numberOfCopies"
            label="Number of Copies"
            type="number"
            id="mtgSettingNumOfCopiesTextField"
            class="mtg-setting-num-of-copies-text-field"
            :rules="numberOfCopiesValidation"
          ></v-text-field>
        </v-col>
        <v-col cols="3" md="4">
          <v-checkbox
            v-model.number="editItem.PrintCashCheckOtherAmount"
            label="Print Cash/Check/Other Amount"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">EFT Payment Types:</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-select
            v-model="editItem.eftPayementTypeSimplifile"
            label="Simplifile"
            :items="bank"
            item-text="bankAltKey"
            item-value="_id"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="editItem.eftPayementTypeOtc"
            label="OTC"
            :items="bank"
            item-text="bankAltKey"
            item-value="_id"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="editItem.eftPayementTypeIrs"
            label="IRS"
            :items="bank"
            item-text="bankAltKey"
            item-value="_id"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-select
            v-model="editItem.eftPayementTypeForte"
            label="Forte"
            :items="bank"
            item-text="bankAltKey"
            item-value="_id"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="editItem.eftPayementTypeCsc"
            label="CSC"
            :items="bank"
            item-text="bankAltKey"
            item-value="_id"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="editItem.eftPayementTypeOther"
            label="Other"
            :items="bank"
            item-text="bankAltKey"
            item-value="_id"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">General Payment Types:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
        <v-select
          label="Cash/ Check Deposit Bank"
          :items="bank"
          item-text="bankAltKey"
          item-value="_id"
          v-model="editItem.cashAndCheckDepositBank"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          label="CC Deposit bank"
          :items="bank"
          item-text="bankAltKey"
          item-value="_id"
          v-model="editItem.creditCardDepositBank"
        ></v-select>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">Forte Configuration:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="editItem.apiAccessId"
            label="Forte API Access ID"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="editItem.apiSecureId"
            label="Forte API Secure Key"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="editItem.forteLocationId"
            label="Forte Location ID"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="mtgSettingSaveBtn"
          class="mtg-setting-save-btn"
          depressed
          color="primary"
          @click="update"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

const bankCrudKey = 'sharedBank';

export default {
  computed: {
    ...mapState({
      editItem: (state) => {
        const settings = { ...state.SystemConfig.mtgSettingItem || {} };
        settings.numberOfCopies = settings.numberOfCopies || 2;
        return settings;
      },
    }),
    ...mapGetters('base/crud', [
      'items',
    ]),
    numberOfCopiesValidation() {
      return [
        (value) => {
          if (value === '' || value === undefined) {
            return true;
          }
          if (parseFloat(value) < 1) {
            return 'Invalid number of copies!';
          }
          return true;
        },
      ];
    },
    bank() {
      const bankObjs = this.items(bankCrudKey) || [];
      const updatedBankObjs = bankObjs.map((b) => ({
        ...b,
        bankAltKey: ` ${b.altKeyBase || ''} - ${b.bankName}`,
      }));
      return [{ _id: null, bankAltKey: '', bankName: '' }, ...updatedBankObjs];
    },
  },
  created() {
    this.loadConfig({ requiredScope: 'mtgConfig' });
    this.loadBanks({
      criteria: {
        notEmptyAltKey: true,
        limit: 0,
      },
      baseCrudKey: bankCrudKey,
    });
  },
  methods: {
    update() {
      if (window.confirm('Are you sure want to update this configuration?')) {
        const payload = { calledScope: 'mtgConfig', item: this.editItem };
        this.$store.dispatch('SystemConfig/updateConfig', payload);
      }
    },
    ...mapActions('shared/bank', [
      'loadBanks',
    ]),
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
  },
};
</script>
