<template>
  <v-row>
    <v-col
      cols="12"
      md="2"
    >
      <v-list
        nav
        dense
      >
        <ProgramList />
      </v-list>
    </v-col>
    <v-col
      cols="12"
      md="10"
    >
      <v-btn
        v-if="isSuperAdmin"
        color="primary darken-1"
        text
        :to="{ name: 'View System Info' }">View System Info
      </v-btn>
      <v-btn
        v-if="isSuperAdmin"
        color="primary darken-1"
        text
        :to="{ name: 'Dev Specific Config' }">*TEST*(Not for PROD)
      </v-btn>
      <v-btn
        v-if="isSuperAdmin"
        color="primary darken-1"
        text
        :to="{ name: 'Migration Manager' }">Migration Manager
      </v-btn>
      <v-tabs>
        <v-tab>Global</v-tab>
        <v-tab-item>
          <GlobalSetting />
        </v-tab-item>

        <v-tab>BANK</v-tab>
        <v-tab-item>
          <BankSetting />
        </v-tab-item>

        <v-tab>OD</v-tab>
        <v-tab-item>
          <ODSetting />
        </v-tab-item>

        <v-tab>WAR</v-tab>
         <v-tab-item>
          <WarSetting />
        </v-tab-item>

        <v-tab>MISC</v-tab>
        <v-tab-item>
          <MiscSetting />
        </v-tab-item>

        <v-tab>MTG</v-tab>
        <v-tab-item>
          <MtgSetting />
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import ProgramList from '@/components/common/ProgramList.vue';
import GlobalSetting from '@/components/SystemConfig/GlobalSetting.vue';
import BankSetting from '@/components/SystemConfig/BankSetting.vue';
import ODSetting from '@/components/SystemConfig/ODSetting.vue';
import WarSetting from '@/components/SystemConfig/WarSetting.vue';
import MiscSetting from '@/components/SystemConfig/MiscSetting.vue';
import MtgSetting from '@/components/SystemConfig/MtgSetting.vue';
import { authRoles } from '@/router/roleGroups';
import { isAuthorised } from '../../store/auth/token';

export default {
  components: {
    ProgramList,
    GlobalSetting,
    BankSetting,
    ODSetting,
    WarSetting,
    MiscSetting,
    MtgSetting,
  },
  computed: {
    isSuperAdmin() {
      return isAuthorised(authRoles.SuperAdmin);
    },
  },
};
</script>
