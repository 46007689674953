<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="defaultBank"
            :items="banksList"
            item-value="id"
            item-text="bankName"
            label="Bank List"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          class="mx-10"
          @click="newForm"
          fab
          dark
          small
          color="indigo"
        >
          <v-icon dark>
            {{icons.mdiPlus}}
          </v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.bankName"
            label="Bank Name"
            required
            :error-messages="errorMsgs"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.bankAddress"
            label="Bank Address"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.bankCode"
            label="Bank Code"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.bankRoutingNumber"
            label="Bank Routing Number"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.bankAccountNumber"
            label="Bank Account Number"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.groupCode"
            label="Group Code"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
        <!-- TODO - migrate dailySection to be renamed altKey in the backend -->
          <v-text-field
            v-model="editItem.dailySection"
            label="Alt Key (for GL export)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text color="blue darken-1"
          @click="update"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  mdiPlus,
} from '@mdi/js';

export default {
  mixins: [validationMixin],
  validations: {
    editItem: {
      bankName: {
        required,
      },
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
    },
    defaultBank: '',
    defaultBankDetails: {
      bankAddress: '',
      bankCode: '',
      bankRoutingNumber: '',
      bankAccountNumber: '',
      groupCode: '',
      dailySection: '',
      bankName: '',
    },
  }),
  computed: {
    errorMsgs() {
      const v = this.$v;
      return (!v.editItem.bankName.$dirty) ? [] : [
        ...(v.editItem.bankName.required) ? [] : ['Required'],
      ];
    },
    editItem() {
      return this.$store.state.SystemConfig.bankSettingItem || this.defaultBankDetails;
    },
    banksList() {
      return this.$store.state.SystemConfig.banksList;
    },
  },
  watch: {
    defaultBank(value) {
      this.$store.commit('SystemConfig/setSelectedData', value);
    },
  },
  created() {
    this.$store.dispatch(
      'SystemConfig/loadConfig',
      { requiredScope: 'bank' },
    );
  },
  methods: {
    update() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (window.confirm('Are you sure want to update this configuration?')) {
          const payload = { calledScope: 'bank', item: this.editItem };
          this.$store.dispatch('SystemConfig/updateConfig', payload);
        }
      }
    },
    newForm() {
      this.$store.commit('SystemConfig/newForm');
      this.defaultBank = '';
    },
  },
};
</script>
