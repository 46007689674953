<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
        >
        <h3 class="text-decoration-underline">Transaction Configuration:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-checkbox
            v-model="editItem.usedLastBusinessDate"
            label="Enable Last Used Business Date"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
        <v-select
          label="Cash/ Check Deposit Bank"
          :items="bank"
          item-text="bankAltKey"
          item-value="_id"
          v-model="editItem.cashAndCheckDepositBank"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          label="CC Deposit bank"
          :items="bank"
          item-text="bankAltKey"
          item-value="_id"
          v-model="editItem.creditCardDepositBank"
        ></v-select>
      </v-col>
      </v-row>
      <v-row>
      <v-col cols="3">
          <v-text-field
            v-model.number="editItem.durationOfCorrection"
            label="Duration of Correction(in Days)"
            type="number"
            ref="miscConfigDurationOfCorrection"
            v-if="isAdminUser"
            :rules="durationValidation"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="editItem.defaultSpecialApportionment"
            label="Default To Special"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="editItem.requireBankAltKey"
            label="Require Bank Alt Key"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">Print Settings:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" md="3">
          <v-text-field
            v-model.number="editItem.numberOfCopies"
            label="Number of Copies"
            type="number"
            :rules="numberOfCopiesValidation"
          ></v-text-field>
        </v-col>
        <v-col cols="3" md="3">
          <v-checkbox
            v-model.number="editItem.PrintCashCheckOtherAmount"
            label="Print Cash/Check/Other Amount"
          ></v-checkbox>
        </v-col>
        <v-col cols="2" md="3">
          <v-text-field
          label="Tax Year"
          v-model.number="editItem.taxYear"
          type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="editItem.printEONReportBoardCounty"
            row
            mandatory
          >
            <template v-slot:label>
              <div><h3>Print EON Report: </h3></div>
            </template>
            <v-radio
              label="Excise Board County"
              value="excise"
            ></v-radio>
            <v-radio
              label="Budget Board County"
              value="budget"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">Default Option For Transaction Payment Type:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="editItem.eftPaymentType"
            label="Default Option For EFT Payment Type"
            :items="cmnEFTTypes"
            item-text="description"
            item-value="name"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="editItem.ccPaymentType"
            label="Default Option For CC Payment Type"
            :items="cmnCardTypes"
            item-text="description"
            item-value="name"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">Forte Configuration:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="editItem.apiAccessId"
            label="Forte API Access ID"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="editItem.apiSecureId"
            label="Forte API Secure Key"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="editItem.forteLocationId"
            label="Forte Location ID"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">
          Daily CR Export Configuration (For both Misc & Mtg):
        </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="editItem.dailyCrOverrideWkst"
            label="Override Wkst on Daily CR Export"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="miscSettingSaveBtn"
          large
          color="primary"
          @click="update"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { isAuthorised } from '../../store/auth/token';

const bankCrudKey = 'sharedBank';
const defaultPrintEONReportBoardCounty = 'excise';

export default {
  computed: {
    ...mapState({
      editItem: (state) => {
        const settings = { ...state.SystemConfig.miscSettingItem || {} };
        settings.numberOfCopies = settings.numberOfCopies || 2;
        settings.printEONReportBoardCounty = settings.printEONReportBoardCounty
          || defaultPrintEONReportBoardCounty;
        return settings;
      },
      cmnEFTTypes: (state) => state.enums.MISCEFTTypeEnum || [],
      cmnCardTypes: (state) => state.enums.MISCCardTypeEnum || [],
    }),
    ...mapGetters('base/crud', [
      'items',
    ]),
    numberOfCopiesValidation() {
      return [
        (value) => {
          if (value === '' || value === undefined) {
            return true;
          }
          if (parseFloat(value) < 1) {
            return 'Invalid number of copies!';
          }
          return true;
        },
      ];
    },
    bank() {
      const bankObjs = this.items(bankCrudKey) || [];
      const updatedBankObjs = bankObjs.map((b) => ({
        ...b,
        bankAltKey: ` ${b.altKeyBase || ''} - ${b.bankName}`,
      }));
      return [{ _id: null, bankAltKey: '', bankName: '' }, ...updatedBankObjs];
    },
    durationValidation() {
      return [
        (value) => {
          if (value === '' || value === undefined) {
            return true;
          }
          if (parseFloat(value) <= 1) {
            return 'Invalid correction day!';
          }
          return true;
        },
      ];
    },
    isAdminUser() {
      // Text field's visibility won't change if user logs in and logs out in Misc settings.
      return isAuthorised(['MiscAdministration']);
    },
  },
  created() {
    this.loadConfig({ requiredScope: 'miscConfig' });
    this.loadBanks({
      criteria: {
        notEmptyAltKey: true,
        limit: 0,
      },
      baseCrudKey: bankCrudKey,
    });
  },
  methods: {
    update() {
      const result = this.$refs.miscConfigDurationOfCorrection.validate();
      if (result && window.confirm('Are you sure want to update this configuration?')) {
        const payload = { calledScope: 'miscConfig', item: this.editItem };
        this.$store.dispatch('SystemConfig/updateConfig', payload);
      }
    },
    ...mapActions('shared/bank', [
      'loadBanks',
    ]),
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
  },
};
</script>
