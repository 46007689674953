<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
        >
        <h3 class="text-decoration-underline">Forte Configuration:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.apiAccessId"
            label="Forte API Access ID"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.apiSecureId"
            label="Forte API Secure Key"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.odTrustLocationId"
            label="OD Trusts Location ID"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
        <h3 class="text-decoration-underline">Transaction Configuration:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
            v-model="editItem.unregisteredDep"
            label="Enable Unregistered Deposits"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
            v-model="editItem.usedLastRegister"
            label="Enable Last Used Registration Date"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
        <v-checkbox
          v-model="editItem.registrationNumber"
          label="Edit Registration Number"
          v-if="isAdminUser"
        ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-checkbox
          v-model="editItem.voucherNumber"
          label="Edit Voucher Number"
          v-if="isAdminUser"
        ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model.number="editItem.durationOfCorrection"
            label="Duration of Correction(in Days)"
            type="number"
            ref="odConfigDurationOfCorrection"
            v-if="isAdminUser"
            :rules="durationValidation"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
        <h3 class="text-decoration-underline">Reconciliation Configuration:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
            v-model="editItem.ccReconciliation"
            label="Enable CC Reconciliation"
          ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
            v-model="editItem.cashCheckReconciliation"
            label="Enable Cash/Check Reconciliation"
          ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
            v-model="editItem.trustReconciliation"
            label="Enable Trust Reconciliation"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-checkbox
            v-model="editItem.enableODPostivePayBank"
            label="Enable Positive Pay Bank Account"
          ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
            id="odSettingHideDrawnBy"
            v-model="editItem.hideDrawnBy"
            label="Hide 'Drawn by' field"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" md="4"></v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
        <h3 class="text-decoration-underline">Print Settings:</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
            v-model="editItem.hideMicrAcctLineOnVoucher"
            label="Hide MICR/Acct line on voucher"
          ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
            v-model="editItem.cashOnlyDepositSlip"
            label="Cash only deposit slip "
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
            v-model="editItem.hideForWhatOnVoucher"
            label="Hide For What on Voucher"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="odSettingSaveBtn"
          depressed
          color="primary"
          @click="update"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { isAuthorised } from '../../store/auth/token';

export default {
  computed: {
    editItem() {
      return this.$store.state.SystemConfig.odSettingItem || {};
    },
    durationValidation() {
      return [
        (value) => {
          if (value === '' || value === undefined) {
            return true;
          }
          if (!Number.isInteger(value)) {
            return 'Invalid correction day!';
          }
          if (parseFloat(value) <= 1) {
            return 'Invalid correction day!';
          }
          return true;
        },
      ];
    },
    isAdminUser() {
      // Text field's visibility won't change if user logs in and logs out in OD settings.
      return isAuthorised(['ODAdministration']);
    },
  },
  created() {
    this.$store.dispatch(
      'SystemConfig/loadConfig',
      { requiredScope: 'odconfig' },
    );
  },
  methods: {
    update() {
      const result = this.$refs.odConfigDurationOfCorrection.validate();
      if (result && window.confirm('Are you sure want to update this configuration?')) {
        const payload = { calledScope: 'odconfig', item: this.editItem };
        this.$store.dispatch('SystemConfig/updateConfig', payload);
      }
    },
  },
};
</script>
