<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">
          Transaction Configuration:
        </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" md="3">
          <v-checkbox
            id="tmcWarSettingFundsOnly"
            class="tmc-war-setting"
            v-model="editItem.fundsOnly"
            label="Funds Only"
          ></v-checkbox>
        </v-col>
        <v-col cols="3" md="3">
          <v-checkbox
            id="tmcWarSettingMultipleFunds"
            class="tmc-war-setting"
            v-model="editItem.multipleFunds"
            label="Multiple Funds per Warrant"
          ></v-checkbox>
        </v-col>
         <v-col cols="3" md="3">
          <v-checkbox
            id="tmcWarSettingUsedLastRegisterDate"
            class="tmc-war-setting"
            v-model="editItem.usedLastRegisterDate"
            label="Enable Last Used Register Date"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">
          Warrants Import / Export Configuration:
        </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="3">
          <v-checkbox
            id="tmcWarSettingCrossRefFund"
            class="tmc-war-setting"
            v-model="editItem.crossRefFund"
            label="Check Cross reference Fund number"
          ></v-checkbox>
        </v-col>
        <v-col cols="3" md="3">
          <v-checkbox
            id="tmcWarSettingAllowImpWithErrInWarNoSeq"
            class="tmc-war-setting"
            v-model="editItem.allowImpWithErrInWarNoSeq"
            label="Allow to proceed import with sequence error in Warrant number(s)"
          ></v-checkbox>
        </v-col>
        <v-col cols="3" md="3">
          <v-checkbox
            id="tmcWarSettingInsufficentFundsWarning"
            class="tmc-war-setting"
            v-model="editItem.insufficentFundsWarning"
            label="Enable Insufficient Funds Warning"
          ></v-checkbox>
        </v-col>
        <v-col cols="3" md="3">
          <v-checkbox
            v-model="editItem.glReportUseRegisterDate"
            label="Use Register Date for GL Export"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">
          Clearing Fund Configuration:
        </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="3">
          <v-checkbox
            id="tmcWarSettingClearingFund"
            class="tmc-war-setting"
            v-model="editItem.clearingFund"
            label="Enable Clearing Fund Number"
          ></v-checkbox>
        </v-col>
        <v-col v-if="editItem.clearingFund"
          cols="8" md="4">
          <v-text-field
            :rules="clearingFundValidation"
            id="tmcWarSettingClearingFundList"
            class="tmc-war-setting"
            v-model="editItem.clearingFundList"
            label="Clearing Funds"
            hint="Clearing Funds should be comma seperated"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">
          Print Warrant Configuration:
        </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="3">
          <v-checkbox
            id="tmcWarSettingPrintWarrant"
            class="tmc-war-setting"
            v-model="editItem.enablePrintWarrant"
            label="Enable Print Warrant"
          ></v-checkbox>
        </v-col>
        <v-col v-if="editItem.enablePrintWarrant"
          cols="8" md="4">
          <v-text-field
            id="tmcWarSettingPrintableFunds"
            class="tmc-war-setting"
            v-model="editItem.fundListForPrintWarrant"
            label="Fund List for Print Warrant"
            hint="Funds should be comma seperated"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">
          Payroll Program Configuration:
        </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" md="3">
          <v-checkbox
            id="tmcWarSettingPayrollRegisterProgram"
            class="tmc-war-setting"
            v-model="editItem.payrollRegisterProgram"
            label="Enable Payroll Register Program"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline" style="color: coral;">
          Data Migration Configuration (*Only for Developemnt purpose):
        </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" md="3">
          <v-checkbox
            id="tmcWarSettingCancelledWarrantAmountFlag"
            class="tmc-war-setting"
            v-model="editItem.cancelledWarrantAmountFlag"
            label="Enable New Mechanism of Cancelled Warrant Amount"
            :disabled="true"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn
            id="WarSettingSaveBtn"
            class="tmc-war-setting"
            depressed
            color="primary"
            @click="update">
            Save
          </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { checkStringEmptiness } from '../../util/shared/vue-global';

export default {
  computed: {
    ...mapState({
      editItem: (state) => {
        const stateCopy = { ...state.SystemConfig.warSettingItem };
        return stateCopy;
      },
    }),
    clearingFundValidation() {
      return [
        (value) => /^(\d{4}\s*-\s*\d+)(,\s*\d{4}\s*-\s*\d+)*$/.test(value) || 'Invalid format (e.g. 1234-5678,)',
      ];
    },
  },
  created() {
    this.$store.dispatch(
      'SystemConfig/loadConfig',
      { requiredScope: 'warconfig' },
    );
  },
  methods: {
    savableEdits() {
      /* format clearing fund list */
      if (!checkStringEmptiness(this.editItem.clearingFundList)) {
        const allClearingFundNumbers = this.editItem.clearingFundList || '';
        const clearingFundNumbers = allClearingFundNumbers.toString().match(/[^,\s][^,]*/g).filter(Boolean);
        this.editItem.clearingFundList = clearingFundNumbers;
      }

      /* format fund list of print warrant */
      if (!checkStringEmptiness(this.editItem.fundListForPrintWarrant)) {
        const fundListForPrintWarrant = this.editItem.fundListForPrintWarrant || '';
        this.editItem.fundListForPrintWarrant = fundListForPrintWarrant.toString().match(/[^,\s][^,]*/g).filter(Boolean);
      }
    },
    update() {
      if (window.confirm('Are you sure want to update this configuration?')) {
        this.savableEdits();
        const payload = { calledScope: 'warconfig', item: this.editItem };
        this.$store.dispatch('SystemConfig/updateConfig', payload);
      }
    },
  },
};
</script>
