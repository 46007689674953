<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.countyName"
            label="County Name"
            :rules="countyNameValidation"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.countyNumber"
            label="County Number"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.treasurerPhone"
            label="Treasurer Phone"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.treasurerFax"
            label="Treasurer Fax"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.treasurerEmail"
            label="Treasurer Email"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.treasurerWebsite"
            label="Treasurer Website"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.treasurerName"
            label="Treasurer Name"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.countyClerkName"
            label="County Clerk's Name"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.countyAssessorName"
            label="County Assessor's Name"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.businessDate"
            label="Business Date(dd/mm/yyyy)"
            type="date"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
        <h3 class="text-decoration-underline">Address:</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-textarea
            v-model="editItem.addressAdd1"
            label="Add1"
            rows="2"
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-textarea
            v-model="editItem.addressAdd2"
            label="Add2"
            rows="2"
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-textarea
            v-model="editItem.addressAdd3"
            label="Add3"
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.addressCity"
            label="City"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.addressState"
            label="State"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="editItem.addressZip"
            label="Zip"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
        <h3 class="text-decoration-underline">Payment Configuration:</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model.number="editItem.convenienceFee"
            type="number"
            label="Convenience Fee(%)"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model.number="editItem.penalty"
            type="number"
            label="Penalty"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
        <h3 class="text-decoration-underline">Forte Prod/Sandbox Setting:</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="editItem.forteApiURL"
            :items="forteApiModesList"
            label="Forte Mode"
            item-value="apiURL"
            item-text="apiMode"
            dense
            outlined
          ></v-select>
          </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          @click="update"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  forteApiModes,
} from '../../util/shared/tmc-global';

export default {
  data: () => ({
    forteApiModesList: forteApiModes,
  }),
  computed: {
    editItem() {
      return this.$store.state.SystemConfig.globalSettingItem || {};
    },
    countyNameValidation() {
      return [
        (value) => !!value || 'Required',
        (value) => {
          const lowerValue = (value || '').toLowerCase();
          return lowerValue.indexOf('county') === -1 || 'Using "county" in the county name is redundant';
        },
      ];
    },
  },
  created() {
    this.$store.dispatch(
      'SystemConfig/loadConfig',
      { requiredScope: 'global' },
    );
  },
  methods: {
    update() {
      if (window.confirm('Are you sure want to update this configuration?')) {
        const payload = { calledScope: 'global', item: this.editItem };
        this.$store.dispatch('SystemConfig/updateConfig', payload);
      }
    },
  },
};
</script>
